import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const Price = () => (
  <Flex alignItems="baseline" justifyContent="space-between" whiteSpace="nowrap">
    <Text color="gray.200" fontSize="14px" mr="8px">
      cena wizyty:
    </Text>
    <Text color="gray.100" fontWeight={600} fontSize="24px">
      300 zł
    </Text>
  </Flex>
);
